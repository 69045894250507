body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Barlow', Barlow, sans-serif;
}

a:active,
a:hover,
a:link,
a:visited {
  color: #37746b;
}
